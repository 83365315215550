<template>
  <Popup @closePopup="uiProductsStore.closeProductImageModal">
    <template v-slot:header>
      <h3>Upload a product image</h3>
    </template>
    <template v-slot:body>
      <form id="product-image-form" @submit.prevent="handleFileUpload">
        <RightSidebarTitle :title="'Select an Image:'" />
        <input
          type="file"
          id="product-image-upload-input"
          ref="productImageInput"
          @change="fetchLocalFile"
        />
        <ErrorMessage v-if="fileTypeError" :message="fileTypeError" />
      </form>
    </template>
    <template v-slot:footer>
      <button
        form="product-image-form"
        type="submit"
        :disabled="!fileToUpload || uiProductsStore.areProductDetailsUpdating"
        class="btn-1 full-width submit-btn"
      >
        Set as Product Image
      </button>
    </template>
  </Popup>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import fileHelpers from "@/utils/file-helpers";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useProductsStore } from "@/stores/products.store";
import { PRODUCT } from "@/constants/product.constants";
import ErrorMessage from "@/components/ErrorMessage.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import Popup from "@/components/Popup.vue";

const uiProductsStore = useUIProductsStore();
const productsStore = useProductsStore();

const fileToUpload = ref<any | undefined>(undefined);
const fileTypeError = ref<string>("");
const productImageInput = ref<HTMLInputElement | undefined>(undefined);
const fetchLocalFile = (): void => {
  if (productImageInput.value?.files) {
    const fileType = productImageInput.value.files[0].type;
    if (!fileType || !fileHelpers.isValidImageFileType(fileType)) {
      fileTypeError.value = PRODUCT.ERRORS.imageFileType;
      return;
    }
    fileTypeError.value = "";
    fileToUpload.value = productImageInput.value.files[0];
  }
};
const handleFileUpload = (): void => {
  if (
    uiProductsStore.productToAddImage &&
    Number(uiProductsStore.productToAddImage) > 0
  ) {
    // if we change an image in an existing product
    const formData = new FormData();
    formData.append("image", fileToUpload.value);
    formData.append("product_id", `${uiProductsStore.productToAddImage}`);
    const payload = {
      formData: formData,
      product_id: uiProductsStore.productToAddImage
    };
    productsStore.addProductImage(payload).then(() => {
      uiProductsStore.closeProductImageModal();
    });
  } else {
    // if a product is new
    uiProductsStore.setNewProductImage(fileToUpload.value);
    uiProductsStore.closeProductImageModal();
  }
};
</script>
<style scoped lang="scss"></style>
