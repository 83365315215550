<template>
  <Popup
    :data-testing-modal-card="'attach-file-card'"
    @closePopup="uiFilesStore.closeAttachFileModal"
  >
    <template v-slot:header>
      <h3>
        <span v-if="uiFilesStore.shouldBeSetAsMediaContent"
          >Set Media Content for:&nbsp;</span
        >
        <span v-else>Attach content to&nbsp;</span>
        <i>{{ name }}</i>
      </h3>
    </template>
    <template v-slot:body>
      <b-tabs
        data-testing="tabs"
        v-model="activeTab"
        :destroy-on-hide="true"
        :animated="false"
        :position="'is-centered'"
        :expanded="true"
        class="attach-file-tabs"
      >
        <b-tab-item label="Upload">
          <UploadForm />
        </b-tab-item>
        <b-tab-item label="Link to online content">
          <OnlineForm />
        </b-tab-item>
        <b-tab-item label="Select from Library">
          <FileLibraryForm />
        </b-tab-item>
      </b-tabs>
    </template>
  </Popup>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { ProductItem } from "@/models/product.model";
import OnlineForm from "@/components/popups/attachments/attach-file/OnlineForm.vue";
import UploadForm from "@/components/popups/attachments/attach-file/UploadForm.vue";
import FileLibraryForm from "@/components/popups/attachments/attach-file/FileLibraryForm.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import Popup from "@/components/Popup.vue";

const uiFilesStore = useUIFilesStore();

const UPLOAD_FILE = 0;
const ONLINE_ATTACHMENT = 1;
const EXISTING_ATTACHMENT = 2;
const activeTab = ref<number>(UPLOAD_FILE);

const target = computed<ContentStructureSection | ProductItem | null>(() => {
  if (uiFilesStore.sectionToAttachFile) {
    return uiFilesStore.sectionToAttachFile;
  }
  if (uiFilesStore.productToAttachFile) {
    return uiFilesStore.productToAttachFile;
  }
  return null;
});
const name = computed<string>(() => {
  return target.value ? target.value.name : "";
});
</script>

<style lang="scss" scoped>
.attach-file {
  width: auto;
  min-width: 31rem;
}
</style>
<style lang="scss">
.attach-file-tabs.b-tabs {
  .tabs ul {
    padding-left: 0;
  }

  .tab-content {
    padding: 1rem 0;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 23rem;
    min-height: 17.5rem;

    .submit-btn {
      margin-top: auto;
    }
  }
}
</style>
