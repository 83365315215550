import { defineStore } from "pinia";
import { ref } from "vue";
import { Programme } from "@/models/programme.model";

export const useUIProgrammesStore = defineStore("uiProgrammes", () => {
  const programmeForDisplay = ref<Programme | null>(null);
  const openProgrammeSidebar = (programme: Programme) => {
    programmeForDisplay.value = programme;
  };
  const closeProgrammeSidebar = () => {
    programmeForDisplay.value = null;
  };

  return {
    programmeForDisplay,
    openProgrammeSidebar,
    closeProgrammeSidebar
  };
});
