import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { SectionToDisplay } from "@/models/store/ui-state/section-to-display.model";
import {
  AddContentAddChildSection,
  NewSectionDetails,
  NewSectionDetailsForPopup
} from "@/models/store/ui-state/new-section-details.model";
import { ProductXAPIErrors } from "@/models/x-api.model";
import { SelectedBatch } from "@/models/content-structure-batch.model";
import { ContentTemplate } from "@/models/content-templates.model";

export const useUIContentStore = defineStore("uiContent", () => {
  const fileLocationForPreview = ref<string | undefined>(undefined);
  const setFileLocationToPreview = (fileLocation: string | undefined) => {
    fileLocationForPreview.value = fileLocation;
  };

  const sectionToDisplay = ref<SectionToDisplay | null>(null);
  const openContentSidebar = (params: SectionToDisplay) => {
    closeNewSectionSidebar();
    sectionToDisplay.value = params;
  };
  const closeContentSidebar = () => {
    sectionToDisplay.value = null;
  };

  const newSectionDetails = ref<NewSectionDetails | null>(null);
  const openNewSectionSidebar = (details: NewSectionDetails) => {
    closeContentSidebar();
    newSectionDetails.value = details;
  };
  const closeNewSectionSidebar = () => {
    newSectionDetails.value = null;
  };

  const isContentSidebarExpanded = ref<boolean>(false);
  const toggleContentSidebarWidth = () => {
    isContentSidebarExpanded.value = !isContentSidebarExpanded.value;
  };

  const openSectionIds = ref<number[]>([]);
  const toggleSectionOpen = (sectionId: number) => {
    if (openSectionIds.value.includes(sectionId)) {
      openSectionIds.value = openSectionIds.value.filter(e => e !== sectionId);
    } else {
      openSectionIds.value.push(sectionId);
    }
  };
  const expandAllSections = (sectionIds: number[]) => {
    sectionIds.forEach((id: number) => {
      if (!openSectionIds.value.includes(id)) {
        openSectionIds.value.push(id);
      }
    });
  };
  const collapseAllSections = () => {
    openSectionIds.value = [];
  };

  const invalidSectionsIds = ref<number[]>([]);
  const collectInvalidSectionsIds = (sectionId: number) => {
    if (!invalidSectionsIds.value.includes(sectionId)) {
      invalidSectionsIds.value.push(sectionId);
    }
  };
  const cleanInvalidSectionsIds = () => {
    invalidSectionsIds.value = [];
  };

  const areAllSectionsVisible = ref<boolean>(true);
  const toggleSectionsVisibility = () => {
    areAllSectionsVisible.value = !areAllSectionsVisible.value;
  };
  const resetSectionsVisibility = () => {
    areAllSectionsVisible.value = true;
  };

  const XAPIErrors = ref<ProductXAPIErrors | null>(null);
  const setXAPIErrors = (payload: ProductXAPIErrors) => {
    XAPIErrors.value = payload;
  };
  const clearXAPIErrors = () => {
    XAPIErrors.value = null;
  };

  const selectedBatches = ref<SelectedBatch[]>([]);
  const toggleSelectedBatch = (params: SelectedBatch) => {
    if (
      selectedBatches.value.some((item: SelectedBatch) => item.id === params.id)
    ) {
      selectedBatches.value = selectedBatches.value.filter(
        e => e.id !== params.id
      );
    } else {
      selectedBatches.value = [params].concat(selectedBatches.value);
    }
  };
  const clearSelectedBatches = () => {
    selectedBatches.value = [];
  };

  const addContentIsRoot = ref<boolean | null>(null);
  const addContentName = ref<string | null>(null);
  const addContentAllowedChildSections = ref<ContentTemplate[] | null>(null);
  const isAddContentModalOpen = computed<boolean>(() => {
    return !!addContentAllowedChildSections.value;
  });
  const addContentAddChildSection = ref<AddContentAddChildSection | null>(null);
  const openAddContentModal = (params: NewSectionDetailsForPopup) => {
    addContentIsRoot.value = params.isRoot;
    addContentName.value = params.name;
    addContentAllowedChildSections.value = params.allowedChildSections;
    addContentAddChildSection.value = params.addSection;
  };
  const closeAddContentModal = () => {
    addContentIsRoot.value = null;
    addContentName.value = null;
    addContentAllowedChildSections.value = null;
    addContentAddChildSection.value = null;
  };

  const isEditContentPathModalOpen = ref<boolean>(false);
  const openEditContentPathModal = () => {
    isEditContentPathModalOpen.value = true;
  };
  const closeEditContentPathModal = () => {
    isEditContentPathModalOpen.value = false;
  };

  return {
    fileLocationForPreview,
    setFileLocationToPreview,
    sectionToDisplay,
    openContentSidebar,
    closeContentSidebar,
    newSectionDetails,
    openNewSectionSidebar,
    closeNewSectionSidebar,
    isContentSidebarExpanded,
    toggleContentSidebarWidth,
    openSectionIds,
    toggleSectionOpen,
    expandAllSections,
    collapseAllSections,
    invalidSectionsIds,
    collectInvalidSectionsIds,
    cleanInvalidSectionsIds,
    areAllSectionsVisible,
    toggleSectionsVisibility,
    resetSectionsVisibility,
    XAPIErrors,
    setXAPIErrors,
    clearXAPIErrors,
    selectedBatches,
    toggleSelectedBatch,
    clearSelectedBatches,
    addContentIsRoot,
    addContentName,
    addContentAllowedChildSections,
    isAddContentModalOpen,
    addContentAddChildSection,
    openAddContentModal,
    closeAddContentModal,
    isEditContentPathModalOpen,
    openEditContentPathModal,
    closeEditContentPathModal
  };
});
