<template>
  <div class="preview-block">
    <div class="preview-header">
      <h3> Preview Mode: Media Content </h3>
      <button
        class="btn-3"
        title="Close Media Content Preview"
        aria-label="close"
        @click="$emit('closePreview')"
        data-testing="close-right-sidebar"
      >
        <SVGIcon :path="mdiClose" type="is-white" size="24" />
      </button>
    </div>
    <div class="preview-container">
      <div class="iframe-box">
        <div class="iframe-box-header">
          <div v-if="section.concepts[0]" class="concept-name">
            {{ section.concepts[0].name }}
            <div class="concept-message">
              {{ section.name }}
            </div>
          </div>
          <div v-else class="concept-name">
            {{ section.name }}
          </div>
          <div v-if="path" class="path-name">
            {{ path.name }}
            <div class="path-message"> Guided Learning Loop </div>
          </div>
          <div v-else class="path-name"> Guided Learning Loop </div>
        </div>
        <div class="iframe-content">
          <img
            v-if="checkIfExternalContentIsImage()"
            class="content"
            :src="getContentLink()"
            alt="image"
          />
          <iframe v-else class="content" :src="getContentLink()" />
        </div>
        <div class="iframe-footer">
          <button :disabled="true" class="button"> Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { AssetLocation } from "@/models/assets.model";
import { mdiClose } from "@mdi/js";
import { useUIContentStore } from "@/stores/ui-content.store";

const uiContentStore = useUIContentStore();

defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  path: {
    type: Object as PropType<AssetLocation>
  }
});

const getContentLink = (): string => {
  if (uiContentStore.fileLocationForPreview) {
    if (uiContentStore.fileLocationForPreview.includes("youtu")) {
      const linkWithoutPostfix =
        uiContentStore.fileLocationForPreview.split("&")[0];
      const prefixesToSearch = [
        "watch?v=",
        "v/",
        "watch?vi=",
        "vi/",
        "e/",
        "?v="
      ];
      const searchValue = prefixesToSearch.find((element: string) => {
        return linkWithoutPostfix.includes(element);
      });
      if (linkWithoutPostfix.includes("youtube")) {
        return searchValue
          ? linkWithoutPostfix.replace(searchValue, "embed/")
          : "";
      }
      if (linkWithoutPostfix.includes("youtu.be")) {
        return (
          "https://www.youtube.com/embed/" +
          linkWithoutPostfix.split("https://youtu.be")[1]
        );
      }
    }
    if (uiContentStore.fileLocationForPreview.includes("onedrive")) {
      return uiContentStore.fileLocationForPreview.replaceAll("&amp;", "&");
    }
    return uiContentStore.fileLocationForPreview;
  }
  return "";
};
const checkIfExternalContentIsImage = (): boolean => {
  const contentLink = getContentLink();
  return !!contentLink && contentLink.match(/\.(jpeg|jpg|gif|png)$/) !== null;
};
</script>

<style lang="scss" scoped>
.preview-block {
  width: 50%;
  height: 100%;
  position: absolute;
  right: $right-sidebar-width-sm;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  transition: width 0.3s ease;
  @media (min-width: $breakpoint-md) {
    right: $right-sidebar-width-md;
  }
}
.preview-header {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 2rem;
  height: 3rem;
  background-color: $primary-color;
  border-top-left-radius: $border-radius;
  button {
    color: white;
  }
}
.preview-container {
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
}
.iframe-box {
  display: flex;
  flex-direction: column;
  border: 1px solid $dark;
  box-shadow: 0 0 10px 0 $grey60;
  margin: 0 auto;
  max-width: 800px;
  height: 100%;
}
.iframe-box-header {
  border-bottom: 1px solid $dark;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}
.iframe-content {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $dark;
  padding: 1rem 2rem;
  min-height: 380px;
  height: 100%;
  background-color: white;
}
.iframe-footer {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
}
.content {
  display: block;
}
iframe.content {
  width: 100%;
  height: 100%;
}
.concept-name,
.path-name {
  font-weight: bold;
}
.concept-message {
  font-size: 12px;
  padding-left: 5px;
  font-weight: lighter;
}
.path-message {
  font-size: 12px;
  font-weight: lighter;
}
.button {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 15px;
  right: 20px;
}
</style>
