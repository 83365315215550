<template>
  <form @submit.prevent="handleFileUpload">
    <div class="margin-b-standard">
      <RightSidebarTitle :title="'Select a file:'" />
      <input
        type="file"
        id="file-upload-input"
        ref="fileUploadInput"
        data-testing="file-upload-input"
        @change="fetchLocalFile"
      />
    </div>

    <div class="margin-b-standard">
      <RightSidebarTitle :title="'Name:'">
        <Hint :type="'info'" :position="'right'">
          <p>Good naming makes it easier to find files/references later</p>
        </Hint>
      </RightSidebarTitle>
      <input v-model.trim="fileUploadName" />
    </div>
    <button
      data-testing="upload-attachfile-btn"
      :disabled="!fileToUpload"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent"
        >Set as Media Content</span
      >
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts" setup>
import Hint from "@/components/Hint.vue";
import { onMounted, ref } from "vue";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";

const uiFilesStore = useUIFilesStore();
const { uploadFile } = useFileManager();

const fileUploadInput = ref<HTMLInputElement | null>(null);
onMounted(() => {
  if (fileUploadInput.value) {
    fileUploadInput.value.focus();
  }
});

const fileToUpload = ref<File | undefined>(undefined);
const fileUploadName = ref<string>("");

const fetchLocalFile = (): void => {
  if (fileUploadInput.value && fileUploadInput.value.files) {
    fileToUpload.value = fileUploadInput.value.files[0];
    const suggestedName =
      fileToUpload.value.name.substring(
        0,
        fileToUpload.value.name.lastIndexOf(".")
      ) || fileToUpload.value.name;
    fileUploadName.value = fileUploadName.value
      ? fileUploadName.value
      : suggestedName;
  }
};
const uploadFileToProduct = (): void => {
  if (
    uiFilesStore.productToAttachFile &&
    uiFilesStore.fileAttachmentType &&
    fileToUpload.value
  ) {
    const formData = new FormData();
    formData.append("file", fileToUpload.value);
    formData.append("name", fileUploadName.value);
    formData.append("product_id", `${uiFilesStore.productToAttachFile.id}`);
    formData.append("attachment_type", `${uiFilesStore.fileAttachmentType}`);
    const payload = {
      formData: formData,
      productId: uiFilesStore.productToAttachFile.id
    };
    uploadFile(payload);
  }
};
const uploadFileToSection = (): void => {
  if (uiFilesStore.sectionToAttachFile && fileToUpload.value) {
    const formData = new FormData();
    formData.append("file", fileToUpload.value);
    formData.append("name", fileUploadName.value);
    formData.append(
      "product_id",
      `${uiFilesStore.sectionToAttachFile.product_id}`
    );
    formData.append("section_id", `${uiFilesStore.sectionToAttachFile.id}`);
    if (uiFilesStore.shouldBeSetAsMediaContent) {
      formData.append("is_media_content", "true");
    }
    const payload = {
      formData: formData,
      productId: uiFilesStore.sectionToAttachFile.product_id
    };
    uploadFile(payload);
  }
};
const handleFileUpload = (): void => {
  if (uiFilesStore.sectionToAttachFile) {
    uploadFileToSection();
  } else {
    uploadFileToProduct();
  }
  uiFilesStore.closeAttachFileModal();
};
</script>
<style lang="scss" scoped></style>
