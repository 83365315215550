import { defineStore } from "pinia";
import { ref } from "vue";
import { OrganizationUser } from "@/models/user.model";

export const useUIUsersStore = defineStore("uiUsers", () => {
  const userForDisplay = ref<OrganizationUser | null>(null);
  const openUserSidebar = (user: OrganizationUser) => {
    userForDisplay.value = user;
  };
  const closeUserSidebar = () => {
    userForDisplay.value = null;
  };

  return {
    userForDisplay,
    openUserSidebar,
    closeUserSidebar
  };
});
