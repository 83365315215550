<template>
  <Popup
    :data-testing-modal-card="'remove-attachment-card'"
    @closePopup="uiFilesStore.closeRemoveAttachmentModal"
  >
    <template v-slot:header>
      <h3>
        Remove attachment <i>{{ uiFilesStore.fileToRemoveLink.name }}</i>
      </h3>
    </template>
    <template v-slot:body>
      <p>Removing this attachment will remove it from this piece of content</p>
      <p v-if="shouldBeUnsetAsMediaContent"
        >Removing this attachment from the list will also unset it as the media
        content</p
      >
      <p>The attachment will still be available in the Attachments library</p>
      <p
        >To permanently delete this attachment, it must be deleted it from the
        <router-link :to="{ name: 'files' }" class="btn-3"
          >Attachment library
        </router-link>
      </p>
    </template>
    <template v-slot:footer>
      <button
        :disabled="filesStore.areFilesUpdating"
        class="btn-1 full-width margin-t-standard"
        data-testing="remove-attachment-btn"
        @click="removeAttachment"
      >
        Remove
      </button>
    </template>
  </Popup>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import { useFilesStore } from "@/stores/files.store";
import Popup from "@/components/Popup.vue";

const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();
const { unlinkFileFromSection } = useFileManager();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  shouldBeUnsetAsMediaContent: {
    required: false,
    default: false,
    type: Boolean
  }
});

const removeAttachment = (): void => {
  if (uiFilesStore.fileToRemoveLink) {
    unlinkFileFromSection({
      productId: props.section.product_id,
      linkId: uiFilesStore.fileToRemoveLink.file_to_section_id
    }).then(() => {
      uiFilesStore.closeRemoveAttachmentModal();
    });
  }
};
</script>
<style lang="scss" scoped></style>
