<template>
  <form @submit.prevent="attachNewFile">
    <div class="margin-b-standard">
      <RightSidebarTitle :title="'Link to online content:'">
        <Hint :type="'info'" :position="'right'">
          <p
            >This could be a link to a sharepoint doc, or even a youtube
            video</p
          >
        </Hint>
      </RightSidebarTitle>
      <input
        id="file-location"
        ref="firstLocationInput"
        :class="{ error: isLocationInValid }"
        @focus="resetLocationValidator"
        @blur="onLocationBlur"
        v-model="fileLocation"
        data-testing="attach-file-location"
      />
      <ErrorMessage
        v-if="isLocationInValid"
        :message="'Must be a valid address'"
      />
    </div>
    <div class="margin-b-standard">
      <RightSidebarTitle :title="'Name:'">
        <Hint :type="'info'" :position="'right'">
          <p>Good naming makes it easier to find files/references later</p>
        </Hint>
      </RightSidebarTitle>
      <input v-model="fileName" data-testing="attach-new-file-name" />
    </div>

    <button
      data-testing="attach-file-btn"
      :disabled="disableButton"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent"
        >Set as Media Content</span
      >
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts" setup>
import Hint from "@/components/Hint.vue";
import fileHelpers from "@/utils/file-helpers";
import {
  OnlineFileToAttachToProduct,
  OnlineFileToAttachToSection
} from "@/models/api/files.model";
import { computed, onMounted, ref } from "vue";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import ErrorMessage from "@/components/ErrorMessage.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";

const uiFilesStore = useUIFilesStore();
const { addOnlineAttachment } = useFileManager();

const fileName = ref<string>("");
const fileLocation = ref<string>("");
const isFileAttached = ref<boolean>(false);
const fileToSetAsMediaContentId = ref<number | undefined>(undefined);

const isLocationInValid = ref<boolean>(false);
const isCurrentLocationInValid = computed<boolean>(() => {
  return (
    !fileHelpers.isAValidURL(fileLocation.value) &&
    !fileHelpers.isAValidPathToTheLocalFile(fileLocation.value)
  );
});
const validateFileLocation = (): void => {
  if (fileLocation.value.length > 0) {
    isLocationInValid.value = isCurrentLocationInValid.value;
  }
};
const resetLocationValidator = (): void => {
  isLocationInValid.value = false;
};

const attachNewFileToSection = (): void => {
  if (uiFilesStore.sectionToAttachFile) {
    const payload = {
      productId: uiFilesStore.sectionToAttachFile.product_id,
      name: fileName.value,
      location: fileLocation.value,
      section_id: uiFilesStore.sectionToAttachFile.id,
      is_media_content: uiFilesStore.shouldBeSetAsMediaContent
    } as OnlineFileToAttachToSection;
    addOnlineAttachment(payload);
  }
};
const attachNewFileToProduct = (): void => {
  if (uiFilesStore.productToAttachFile) {
    addOnlineAttachment({
      productId: uiFilesStore.productToAttachFile.id,
      product_id: uiFilesStore.productToAttachFile.id,
      name: fileName.value,
      location: fileLocation.value,
      attachment_type: uiFilesStore.fileAttachmentType
    } as OnlineFileToAttachToProduct);
  }
};
const attachNewFile = (): void => {
  if (isLocationInValid.value) {
    return;
  }
  if (uiFilesStore.sectionToAttachFile) {
    attachNewFileToSection();
  } else {
    attachNewFileToProduct();
  }
  uiFilesStore.closeAttachFileModal();
};

const onLocationBlur = (): void => {
  if (!fileName.value && !isCurrentLocationInValid.value) {
    fileName.value = fileHelpers.getFileNameFromURL(fileLocation.value);
  }
  validateFileLocation();
};
const disableButton = computed<boolean>(() => {
  return (
    !fileName.value || !fileLocation.value || isCurrentLocationInValid.value
  );
});

const firstLocationInput = ref<HTMLInputElement | null>(null);
onMounted(() => {
  if (firstLocationInput.value) {
    firstLocationInput.value.focus();
  }
});
</script>
<style lang="scss" scoped></style>
