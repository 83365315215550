<template>
  <Collapse>
    <template v-slot:title>
      <span>{{ title }}</span>
    </template>
    <template v-slot:content>
      <p class="pb-0" v-if="!canEditContent">{{ section.instruction }}</p>
      <textarea
        v-else
        id="description-text"
        ref="description-text"
        v-model="instruction"
        @blur="saveDescriptionText"
        data-testing="description"
      ></textarea>
    </template>
  </Collapse>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { useContentStore } from "@/stores/content.store";
import { showToast } from "@/services/notification-manager";
import { SECTION } from "@/constants/assets.constant";

const contentStore = useContentStore();

const props = defineProps({
  sectionId: {
    required: true,
    type: Number
  },
  productId: {
    required: true,
    type: Number
  },
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  canEditContent: {
    required: true,
    type: Boolean
  }
});

const title = computed<string>(() => {
  return `Description for ${props.section.name}`;
});

const instruction = ref<string>("");
onMounted(() => {
  instruction.value = props.section.instruction;
});
watch(
  () => props.section,
  newVal => {
    if (newVal) {
      instruction.value = newVal.instruction;
    }
  }
);
const saveDescriptionText = (): void => {
  contentStore
    .upsertSections({
      productIds: [props.productId],
      sections: [
        {
          id: props.sectionId,
          instruction: instruction.value
        }
      ]
    })
    .catch((error: Error) => {
      showToast({
        message: `${SECTION.ERRORS.descriptionNotUpdated}: ${error.message}`,
        type: "is-danger"
      });
    });
};
</script>
<style lang="scss" scoped>
.description-container:focus-within .plus-icon {
  display: none;
}
.description-text.editing {
  border: 1px solid black;
  padding: 0.5rem;
}
button {
  .plus-icon {
    color: $primary-color;
  }
  &:focus .plus-icon,
  &:hover .plus-icon {
    color: $primary-color-light;
  }
  &:hover[title]::after {
    white-space: pre;
  }
}
textarea {
  resize: none;
}
</style>
