import { defineStore } from "pinia";
import { ref } from "vue";
import { Curriculum } from "@/models/curriculum.model";

export const useUICurriculumsStore = defineStore("uiCurriculums", () => {
  const curriculumForDisplay = ref<Curriculum | null>(null);
  const openCurriculumSidebar = (curriculum: Curriculum) => {
    curriculumForDisplay.value = curriculum;
  };
  const closeCurriculumSidebar = () => {
    curriculumForDisplay.value = null;
  };

  return {
    curriculumForDisplay,
    openCurriculumSidebar,
    closeCurriculumSidebar
  };
});
