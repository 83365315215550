<template>
  <Popup
    :data-testing-modal-card="'upload-file-card'"
    @closePopup="uiFilesStore.closeAttachmentVersionModal"
  >
    <template v-slot:header>
      <h3>
        Upload a new version of <i>{{ uiFilesStore.fileForNewVersion.name }}</i>
      </h3>
    </template>
    <template v-slot:body>
      <form id="upload-file-version" @submit.prevent="handleFileUpload">
        <RightSidebarTitle :title="'Select an Attachment:'" />
        <input
          id="file-version-input"
          ref="fileVersionInput"
          :disabled="filesStore.areFilesUpdating"
          data-testing="file-version-input"
          type="file"
          @change="fetchLocalFile"
        />
      </form>
    </template>
    <template v-slot:footer>
      <button
        :disabled="!fileToUpload || filesStore.areFilesUpdating"
        class="btn-1 full-width"
        data-testing="upload-file-version-btn"
        form="upload-file-version"
      >
        Upload new version
      </button>
    </template>
  </Popup>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import { useFilesStore } from "@/stores/files.store";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import Popup from "@/components/Popup.vue";

const { uploadFileVersion } = useFileManager();
const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();

const props = defineProps({
  productId: {
    required: true,
    type: [Number, String]
  }
});

const fileVersionInput = ref<HTMLInputElement | null>(null);
const fileToUpload = ref<File | undefined>(undefined);

const fetchLocalFile = (): void => {
  if (fileVersionInput.value && fileVersionInput.value.files) {
    fileToUpload.value = fileVersionInput.value.files[0];
  }
};
const handleFileUpload = (): void => {
  if (
    uiFilesStore.fileForNewVersion &&
    !uiFilesStore.fileForNewVersion.is_external &&
    fileToUpload.value
  ) {
    const formData = new FormData();
    formData.append("file", fileToUpload.value);
    formData.append("id", String(uiFilesStore.fileForNewVersion.id));
    uploadFileVersion({
      formData: formData,
      productId: Number(props.productId)
    }).then(() => {
      uiFilesStore.closeAttachmentVersionModal();
    });
  } else {
    console.log("update path for external file");
  }
};
</script>

<style lang="scss" scoped></style>
