<template>
  <Popup
    :data-testing-modal-card="'rename-file-card'"
    @closePopup="uiFilesStore.closeRenameAttachmentModal"
  >
    <template v-slot:header>
      <h3>Rename attachment</h3>
    </template>
    <template v-slot:body>
      <form id="rename-attachment" @submit.prevent="rename">
        <RightSidebarTitle :title="'Enter a new attachment name:'" />
        <input
          v-model.trim="newName"
          :disabled="filesStore.areFilesUpdating"
          data-testing="rename-file-input"
          type="text"
        />
      </form>
    </template>
    <template v-slot:footer>
      <button
        :disabled="!isSubmitBtnEnabled"
        class="btn-1 full-width"
        data-testing="rename-file-btn"
        form="rename-attachment"
        type="submit"
      >
        Rename
      </button>
    </template>
  </Popup>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import Popup from "@/components/Popup.vue";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import { useFilesStore } from "@/stores/files.store";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";

const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();
const { renameAttachment } = useFileManager();

const newName = ref<string>("");
onMounted(() => {
  if (uiFilesStore.fileForRenaming) {
    newName.value = uiFilesStore.fileForRenaming.name;
  }
});
const isSubmitBtnEnabled = computed<boolean>(() => {
  return (
    !!uiFilesStore.fileForRenaming &&
    newName.value !== uiFilesStore.fileForRenaming.name &&
    newName.value !== "" &&
    !filesStore.areFilesUpdating
  );
});
const rename = () => {
  if (uiFilesStore.fileForRenaming) {
    renameAttachment(
      {
        id: uiFilesStore.fileForRenaming.id,
        name: newName.value
      },
      uiFilesStore.fileForRenaming
    ).then(() => {
      uiFilesStore.closeRenameAttachmentModal();
    });
  }
};
</script>
<style lang="scss" scoped></style>
