<template>
  <div>
    <b-collapse
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
      :model-value="workflowIsOpen"
      @open="toggleWorkflow"
      @close="toggleWorkflow"
      data-testing="workflow-collapse"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title title-flex pr-0">
            <span>Tasks in {{ section.name }}</span>
            <span v-if="percentageComplete > 0" class="percent-complete"
              >{{ percentageComplete }}% Complete</span
            >
          </p>
          <a class="card-header-icon">
            <SVGIcon :path="props.open ? mdiMenuDown : mdiMenuUp" :size="24" />
          </a>
        </div>
      </template>

      <div class="card-content outer-container">
        <div class="content">
          <div>
            <ContentWorkflowItem
              v-for="transition in allowedTransitions"
              :key="transition.id"
              :transition="transition"
              :currentState="currentState"
              :isPinnedState="true"
              :submittingStateTransition="submittingStateTransition"
              @requestTransitionConfirmation="requestTransitionConfirmation"
            />
          </div>
        </div>
      </div>
    </b-collapse>
    <ContentWorkflowItem
      v-if="!workflowIsOpen && pinnedState"
      :transition="pinnedState"
      :currentState="currentState"
      :isPinnedState="true"
      :submittingStateTransition="submittingStateTransition"
      @requestTransitionConfirmation="requestTransitionConfirmation"
    />
    <TransitionConfirmation
      v-if="pendingTransition"
      v-model="transitionMessage"
      :pending-transition="pendingTransition"
      :section="section"
      :is-transition-submitting="isTransitionSubmitting"
      @closeTransitionConfirmation="closeTransitionConfirmation"
      @confirmTransition="confirmTransition"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from "vue";
import {
  PendingTransition,
  SidebarTransitionState
} from "@/models/transition-state.model";
import { OrganizationUser } from "@/models/user.model";
import { WorkflowState } from "@/models/workflow.model";
import { formatCourse } from "@/utils/formatTimestamp";
import { TaskStatus } from "@/models/task.model";
import ContentWorkflowService from "@/utils/product/content-workflow.service";
import ContentWorkflowItem from "./ContentWorkflowItem.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  getDeadlineByWorkflowState,
  getTaskStatus
} from "@/utils/tasks-helpers";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { useUsersStore } from "@/stores/users.store";
import { useContentStore } from "@/stores/content.store";
import { useProductFeaturesManager } from "@/composables/product-features-manager";
import { useFeatureFlags } from "@/composables/feature-flags";
import TransitionConfirmation from "@/components/right-sidebars/content-sidebar/content-workflow/TransitionConfirmation.vue";
import { TASKS } from "@/constants/assets.constant";
import { ProductItem } from "@/models/product.model";

const usersStore = useUsersStore();
const contentStore = useContentStore();

const { isWorkflowManagementFeatureEnabled } = useProductFeaturesManager();
const { ffShouldTaskNotificationsBeSent } = useFeatureFlags();

const props = defineProps({
  product: {
    required: true,
    type: Object as PropType<ProductItem>
  },
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  workflowStates: {
    required: true,
    type: Array as PropType<WorkflowState[]>
  },
  currentState: {
    required: true,
    type: Object as PropType<WorkflowState>
  },
  workflowStateFromTitleId: {
    required: true,
    type: Number
  }
});

const getUsersAssignedToOtherWorkflow = (
  workflowUsers: number[]
): OrganizationUser[] => {
  return usersStore.users
    ? usersStore.users.filter((userToCheck: OrganizationUser) => {
        return workflowUsers.some((assignedUserId: number) => {
          return assignedUserId === userToCheck.id;
        });
      })
    : [];
};
const formatTimestampCourse = (date: number): string | undefined => {
  return date ? formatCourse(date) : undefined;
};
const getDeadlineForOtherWorkflow = (
  wfState: WorkflowState
): string | undefined => {
  const taskDeadline: number | undefined = props.product
    ? getDeadlineByWorkflowState(props.section, wfState, props.product)
    : undefined;

  return taskDeadline ? formatTimestampCourse(taskDeadline) : "";
};
const isTransitionAvailableForThisUser = (
  transition: SidebarTransitionState
): boolean => {
  return (
    !!usersStore.currentUser &&
    ContentWorkflowService.isTransitionAvailableForThisUser(
      transition,
      props.workflowStates,
      props.currentState,
      usersStore.currentUser.id,
      usersStore.canUserManageWorkflows
    )
  );
};
const getAllAvailableTransitionsFromThisStatus = (
  stateDetails: {
    id: number;
    name: string;
    status: TaskStatus;
    rank: number;
  },
  assignedUsers: number[]
): PendingTransition[] => {
  // Tasks which are upcoming/complete can be moved to in progress
  // Tasks which are upcoming/inprogress can be moved to complete
  let nextAvailableStatuses: PendingTransition[] = [];

  if (
    stateDetails.status === TaskStatus.UPCOMING ||
    stateDetails.status === TaskStatus.UPCOMING_LATE ||
    stateDetails.status === TaskStatus.FINAL_STATE ||
    stateDetails.status === TaskStatus.COMPLETE
  ) {
    nextAvailableStatuses.push({
      name: stateDetails.name,
      status: TaskStatus.CURRENT,
      availableStatus: "Mark as In Progress",
      id: stateDetails.id,
      assignedUsers: assignedUsers,
      currentStatus: stateDetails.status,
      taskName: stateDetails.name
    });
  }

  if (
    stateDetails.status === TaskStatus.UPCOMING ||
    stateDetails.status === TaskStatus.UPCOMING_LATE ||
    stateDetails.status === TaskStatus.CURRENT ||
    stateDetails.status === TaskStatus.CURRENT_LATE
  ) {
    const nextRankedTask: WorkflowState | undefined = props.workflowStates.find(
      (wf: WorkflowState): boolean => wf.rank === stateDetails.rank + 1
    );

    nextAvailableStatuses.push({
      name: nextRankedTask ? nextRankedTask.name : "",
      status: TaskStatus.COMPLETE,
      availableStatus: "Mark as Complete",
      id: nextRankedTask ? nextRankedTask.id : undefined,
      assignedUsers: assignedUsers,
      currentStatus: stateDetails.status,
      taskName: stateDetails.name
    });
  }

  // tasks which are currently active can be moved to the previous state
  if (
    stateDetails.status === TaskStatus.CURRENT ||
    stateDetails.status === TaskStatus.CURRENT_LATE
  ) {
    if (stateDetails.rank !== props.workflowStates[0].rank) {
      const prevRankedTask: WorkflowState | undefined =
        props.workflowStates.find(
          (wf: WorkflowState): boolean => wf.rank === stateDetails.rank - 1
        );

      nextAvailableStatuses.push({
        name: prevRankedTask ? prevRankedTask.name : "",
        status: TaskStatus.UPCOMING,
        availableStatus: "Move to previous state",
        id: prevRankedTask ? prevRankedTask.id : undefined,
        assignedUsers: assignedUsers,
        currentStatus: stateDetails.status,
        taskName: stateDetails.name
      });
    }
  }

  return nextAvailableStatuses;
};
const allowedTransitions = computed<SidebarTransitionState[]>(() => {
  return props.workflowStates
    .filter((wfState: WorkflowState): boolean => !wfState.isFinalState)
    .map((wfState: WorkflowState): SidebarTransitionState => {
      const { id, name, rank, instruction } = wfState;
      const status: TaskStatus | undefined = props.product
        ? getTaskStatus(props.section, wfState, props.product)
        : undefined;
      if (id && name && status && rank) {
        let stateDetails: SidebarTransitionState = {
          id,
          name,
          rank,
          status,
          instruction,
          isTransitionAvailableForThisUser: false,
          assignedUsers: getUsersAssignedToOtherWorkflow(wfState.users),
          deadline: getDeadlineForOtherWorkflow(wfState),
          availableTransitions: getAllAvailableTransitionsFromThisStatus(
            {
              id,
              name,
              status,
              rank
            },
            wfState.users
          )
        };
        stateDetails.isTransitionAvailableForThisUser =
          isTransitionAvailableForThisUser(stateDetails);
        return stateDetails;
      }
      return {
        assignedUsers: [],
        availableTransitions: [],
        deadline: undefined,
        id: undefined,
        instruction: "",
        isTransitionAvailableForThisUser: false,
        name: undefined,
        rank: undefined,
        status: TaskStatus.COMPLETE
      };
    });
});

const isConfirmTransitionOpen = ref<boolean>(false);
const isTransitionSubmitting = ref<boolean>(false);
const pendingTransition = ref<PendingTransition | undefined>(undefined);
const transitionMessage = ref<string>("");
const requestTransitionConfirmation = (transition: PendingTransition): void => {
  pendingTransition.value = transition;
  isConfirmTransitionOpen.value = true;
};
const closeTransitionConfirmation = (): void => {
  isConfirmTransitionOpen.value = false;
  transitionMessage.value = "";
  pendingTransition.value = undefined;
};
const submittingStateTransition = ref<boolean>(false);
const submitTransition = (
  transition: PendingTransition,
  productId: number
): Promise<void> => {
  submittingStateTransition.value = true;
  return contentStore
    .submitStateTransition({
      shouldProductBeUpdated: !transitionMessage.value,
      productId: productId,
      stateId: transition.id as number,
      sectionId: props.section.id,
      users_to_notify:
        ffShouldTaskNotificationsBeSent &&
        isWorkflowManagementFeatureEnabled.value
          ? transition.assignedUsers
          : [],
      status: {
        statusBefore: (TASKS.STATUSES_DISPLAY_NAME as Record<string, string>)[
          transition.currentStatus
        ],
        statusAfter: (TASKS.STATUSES_DISPLAY_NAME as Record<string, string>)[
          transition.status
        ],
        taskName: transition.taskName
      }
    })
    .then((): void => {
      submittingStateTransition.value = false;
    });
};
const confirmTransition = (): Promise<void> | void => {
  isTransitionSubmitting.value = true;
  const productValue = props.product;
  const pendingTransitionValue = pendingTransition.value;
  if (productValue && pendingTransitionValue && pendingTransitionValue.id) {
    let promises = [];
    promises.push(submitTransition(pendingTransitionValue, productValue.id));
    if (transitionMessage.value) {
      promises.push(
        contentStore.submitMessage({
          productId: productValue.id,
          sectionId: props.section.id,
          message: transitionMessage.value
        })
      );
    }
    return Promise.all(promises).then((): void => {
      closeTransitionConfirmation();
      isTransitionSubmitting.value = false;
    });
  }
};

const workflowIsOpen = ref<boolean>(false);
const toggleWorkflow = (): void => {
  workflowIsOpen.value = !workflowIsOpen.value;
};

const pinnedState = computed<SidebarTransitionState | undefined>(() => {
  let stateInProgress = allowedTransitions.value.find(
    (transition: SidebarTransitionState): boolean =>
      transition.id === props.currentState.id
  );
  if (props.workflowStateFromTitleId) {
    return allowedTransitions.value.find(
      (transition: SidebarTransitionState): boolean =>
        transition.id === props.workflowStateFromTitleId
    );
  }
  return stateInProgress;
});
const percentageComplete = computed<number>(() => {
  const numComplete: number = allowedTransitions.value.filter(
    (wf: SidebarTransitionState) => {
      return wf.status === TaskStatus.COMPLETE;
    }
  ).length;
  const numTasksWhichAreActionable: number = allowedTransitions.value.filter(
    (wf: SidebarTransitionState) => {
      return wf.status !== TaskStatus.FINAL_STATE;
    }
  ).length;
  return Math.round((numComplete * 100) / numTasksWhichAreActionable);
});
</script>
<style lang="scss" scoped>
.card-header {
  box-shadow: none;
  &-title {
    padding: 0.75rem;
    font-weight: 700;
  }
}
.title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    max-width: 100px;
  }
}
.card-content.outer-container {
  padding: 0;
}
.card-content {
  padding: 0 12px 12px; // to match title
}
.content ul {
  margin: 5px;
  padding: 0 1rem 0;
}

.btn-transition {
  font-size: 14px;
  font-weight: normal;
  border: 0;
}
.btn-upcoming {
  background: $border-color-dark;
}
.btn-in-progress {
  background: $warning-color-transparent;
}
.btn-complete {
  background: $success-color;
}
.white-bg {
  background-color: white;
}
.percent-complete {
  color: $primary-color-light;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}
</style>
